import React, { useState,useRef,useEffect } from "react";
import style from "./Profile.module.css";
import ProfileImage from "../../assests/new profile image.png";
import { MdModeEdit } from "react-icons/md";
import SuccessImage from "../../assests/success .png";
import { baseurl } from "../Url";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import { MdClear } from "react-icons/md";
import Loader from "../Loder/Loader";
import silverMedal from "../../assests/SilverPoint.png"
import bronzeMedal from "../../assests/BronzeMedal.png"
import goldMedal from "../../assests/GoldMedal.png"

const Profile = () => {

  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState({
    FirstName: "",
    LastName: "",
    Username: "",
    country: "",
    Email: "",
    profile_image:""
  });
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [contentShow, setContentShow] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState(Array(5).fill(""));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [userID,setUserID]=useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [avatarPopup, setAvatarPopup] = useState(false);
  const [avatarData, setAvatarData] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState({});
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(true);
  const [point,setPoint]=useState("");
  const [viewPopUp,setViewPopup]=useState(false);
  const [viewData,setViewData]=useState({})


  const [achievementDetails,setAchievementDetails] = useState([]);

  const [dailyDrillDetails,setDailyDrillDetails] = useState([])

  const [weeklyWorkOutDetails,setWeaklyWorkoutDetails] =useState([])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleChange = (e, i) => {
    setError("")
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === "") {
      setOTP((prev) => {
        const newOtp = [...prev];
        newOtp[i] = value;
        return newOtp;
      });

      if (value && i < inputRefs.current.length - 1) {
        inputRefs.current[i + 1].focus();
      }
    }
  };

  const handleFocus = (i) => {
    if (inputRefs.current[i]) {
      inputRefs.current[i].select();
    }
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSendEmail = () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    otpSend();
  };

  const handleVerifyOtp = () => {
    const allFieldsFilled = otp.every(value => value.trim() !== "");
    if (!allFieldsFilled) {
      setError("OTP must be 5 characters long.");
      return;
    }
    setError("");
    otpValidate();
  };


  const handleResetPassword = () => {
    let error = false;

    if (!password || !validatePassword(password)) {
      setPasswordError(true);
      error = true;
    } else {
      setPasswordError(false);
    }

    if (!confirmPassword || password !== confirmPassword) {
      setConfirmPasswordError(true);
      error = true;
    } else {
      setConfirmPasswordError(false);
    }

    if (error) {
      return; 
    }
    passwordUpdate();
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password) && !/\s/.test(password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };


  const closePopup = () => {
    setPasswordPopup(false);
    setContentShow("");
    setEmail("");
    setOTP("");
    setPassword("");
    setConfirmPassword("");
    setError("");
    setPasswordError(false)
    setConfirmPasswordError(false)
    setEmailError(false)
    setShowPassword(false)
  };

  const handleCardClick = (data,sectionType) => {
    setViewData({...data,sectionType});
    setViewPopup(true);
  };

  async function profileDetails() {
    const body = {
      user_id: USERID,
      org_id:OrgID
    };

    await fetch(`${baseurl}/user/profile`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
           setProfileData(data.data)
           setTimeout(() => {
            setLoading(false)
           }, 1000);
        }
      });
  }

  async function profileUpdate() {
    const body = {
      user_id: USERID,
      FirstName:profileData.FirstName,
      LastName:profileData.LastName,
      profile_image:profileData.profile_image,
    };

    await fetch(`${baseurl}/user/profile/update`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          toggleEditMode();
          profileDetails();
          setSelectedAvatar({})
        }
      });
  }
  async function avatarDetails() {
    const body = {
      avatar: "avatar_key",
    };

    await fetch(`${baseurl}/avatar/list`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
           setAvatarData(data.data)
        }
      });
  }

  async function otpSend() {
    const body = {
      email: email,
    };

    await fetch(`${baseurl}/otp/send`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("otpSection");
           
        } else{
        }
      });
  }
  async function otpValidate() {
    const body = {
      email: email,
      otp:otp.join("")
    };

    await fetch(`${baseurl}/otp/validate`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("passwordSection");
          setUserID(data.data[0]?.user_id)
          setOTP(Array(5).fill(""));
           
        } else{
          setError(data.description)
        }
      });
  }

  async function passwordUpdate() {
    const body = {
      user_id: userID,
      new_pwd:password
    };

    await fetch(`${baseurl}/password/update`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("successSection");
          setTimeout(() => {
           setPasswordPopup(false)
           setContentShow("")
          }, 2000);
          closePopup()
           
        } 
      });
  }

  async function badgeGet() {
    const body = {
      user_id: USERID,
    };

    await fetch(`${baseurl}/user_badges`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setAchievementDetails(data.data?.Topic_and_module)
          setDailyDrillDetails(data.data?.Daily_Drill)
          setWeaklyWorkoutDetails(data.data?.Weekly_Workout)
          
        }
      });
  }

  async function rewardsGet() {
    const body = {
      user_id: USERID,
    };

    await fetch(`${baseurl}/user/reward_counts`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setPoint(data.data?.Badge)
          
        }
      });
  }


  useEffect(()=>{
   if(USERID){
    profileDetails();
    avatarDetails();
    badgeGet();
    rewardsGet();
   }
  },[USERID])

  return (
    <div className={style.container}>
      <div className={style.profileSection}>
                    <div className={style.header} style={{
                background: point === "Silver"
                  ? "linear-gradient(90deg, #B5B5B5 0%, rgba(150, 150, 150, 0.52) 52.76%, #7B7B7B 100%)"
                  : point === "Bronze"
                  ? "linear-gradient(90deg, #AD673F 0%, rgba(205, 124, 78, 0.78) 54.72%, #773F24 109.82%)"
                  : point === "Gold"
                  ? "linear-gradient(90deg, #DAA200 0%, rgba(230, 178, 27, 0.8) 50.96%, #906B00 100%)"
                  : ""
              }}>
          <p>My Profile</p>
          <span>{point==="Silver"?
                  "SILVER"
                  :point==="Bronze"?"BRONZE"
                  :point==="Gold"?"GOLD"
                  :""}</span>
        </div>
        {loading?
        <Loader/>:
        <div className={style.body}>
          <div className={style.bodyLeft}>
            <div className={style.bodyTopLeft}>
              <div  className={style.avatar}>
                <img
                style={{borderRadius:profileData.profile_image?"14px":""}} 
                src={profileData.profile_image?profileData.profile_image:ProfileImage}
                alt="avatar" 
                 />
              </div>
              <div className={style.avatarText}>
               {editMode? <p onClick={()=>{
                setAvatarPopup(true)
               }} >Change Avatar</p>:""}
              </div>
            </div>
            <div className={style.bodyTopRight}>
            <div className={style.avatar}>
              {point!==""?
                <img
                  src={point==="Silver"?
                  silverMedal
                  :point==="Bronze"?bronzeMedal
                  :point==="Gold"?goldMedal
                  :""} alt="avatar" />
                  :null}
              </div>
            </div>
          </div>
          <div className={style.bodyRight}>
            <div className={style.rightTop}>
              <div className={style.clearButton}>{editMode?<MdClear onClick={()=>{
                toggleEditMode();
                profileDetails();
              }}/> :""}</div>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>First Name</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="FirstName"
                      value={profileData.FirstName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.FirstName}</p>
                  )}
                </div>
                <div className={style.dataCard}>
                  <span>Last Name</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="LastName"
                      value={profileData.LastName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.LastName}</p>
                  )}
                </div>
              </div>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>User Name</span>
                  
                    <p>{profileData.Username}</p>
                </div>
                <div className={style.dataCard}>
                  <span>Country</span>
                    <p>{profileData.country}</p>
                </div>
              </div>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>Email</span>
                    <p>{profileData.Email}</p>
                </div>
              </div>
              <div className={style.editSection}>
                <div className={style.editText}>
                  {editMode ? (
                    <button onClick={profileUpdate}>Save</button>
                  ) : (
                    <>
                      {" "}
                      <MdModeEdit />
                      <p onClick={toggleEditMode} >Edit</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={style.rightBottom}>
              <p onClick={() =>{ 
                setContentShow("emailSection")
                setPasswordPopup(true)}}>Reset Password</p>
            </div>
          </div>
          <div className={style.achievementHeader}>
            <p>Badges</p>
          </div>
          <div className={style.achievementSection}>
            <div className={style.achievementSectionHeder}>
              <p>Topics & Modules</p>
              <span>Based on topic tests and modules average performances  </span>
            </div>
            <div className={style.achievementGrid}>
              {achievementDetails&&achievementDetails.length>0&&achievementDetails.map((data)=>(
                 <div key={data.id} className={style.achievementCard}
                 onClick={() => handleCardClick(data,"achievement")}
                 >
                 <div className={style.achievementCardImage}>
                   <img src={data.status?data.active_img:data.default_img} alt="img"/>
                 </div>
                 <div className={`${style.achievementCardText} ${data.status ? style.activeText : ''}`}>
                   <p>{data.name} </p>
                 </div>
                 </div>
              ))}
             
            </div>
          </div>
          <div className={style.dailyDrillSection}>
            <div className={style.dailyDrillSectionHeder}>
              <p>Daily Drill</p>
              <span>Based on daily drill completion consistency  </span>
            </div>
            <div className={style.achievementGrid}>
              {dailyDrillDetails&&dailyDrillDetails.length>0&&dailyDrillDetails.map((data)=>(
                 <div key={data.id} className={style.achievementCard}
                 onClick={() => handleCardClick(data,"daily")}
                 >
                 <div className={style.achievementCardImage}>
                   <img src={data.status?data.active_img:data.default_img} alt="img"/>
                 </div>
                 <div className={`${style.achievementCardText} ${data.status ? style.activeDailyDrillText : ''}`}>
                   <p>{data.name} </p>
                 </div>
                 </div>
              ))}
             
            </div>
          </div>
          <div className={style.weeklyWorkoutSection}>
            <div className={style.weeklyWorkoutSectionHeder}>
              <p>Weekly Workout</p>
              <span>Based on weekly workouts performances  </span>
            </div>
            <div className={style.achievementGrid}>
              {weeklyWorkOutDetails&&weeklyWorkOutDetails.length>0&&weeklyWorkOutDetails.map((data)=>(
                 <div key={data.id} className={style.achievementCard}
                 onClick={() => handleCardClick(data,"weekly")}
                 >
                 <div className={style.achievementCardImage}>
                   <img src={data.status?data.active_img:data.default_img} alt="img"/>
                 </div>
                 <div className={`${style.achievementCardText} ${data.status ? style.activeWeeklyWorkoutText : ''}`}>
                   <p>{data.name} </p>
                 </div>
                 </div>
              ))}
             
            </div>
          </div>
        </div>
       }
      </div>
      {passwordPopup ? (
        <>
          <div className={style.popupOverlay} onClick={closePopup}></div>
          <div className={style.popup}>
            {contentShow === "emailSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>
                    Receive a code in your email to reset your password.
                  </span>
                </div>
                <div className={style.cardBody}>
                <div className={`${style.emailInput} ${emailError && style.errorInput}`}>
                  <input
                  type="text"
                   placeholder="Email"
                   name="Email"
                   required
                   value={email}
                   onChange={(e)=>{
                    setEmail(e.target.value)
                    setEmailError(false);
                   }}
                      />
                </div>
                  <div className={style.cardButton}>
                    <button onClick={handleSendEmail}>Send</button>
                  </div>
                  <div className={style.cardBottomText}>
                    <p>
                      Back to <span>Login</span>
                    </p>
                  </div>
                </div>
              </div>
            ) : contentShow === "otpSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>Enter the verification code below.</span>
                </div>
                <div className={style.cardBody}>
                  <div className={style.otpSection}>
                  {otp.map((_, i) => (
                      <div key={i} className={style.otpInput}>
                        <input
                          ref={(el) => (inputRefs.current[i] = el)}
                          maxLength={1}
                          required
                          value={otp[i]}
                          onChange={(e) => handleChange(e, i)}
                          onFocus={() => handleFocus(i)}
                        />
                      </div>
                    ))}
                  </div>
                  {error && <p className={style.error}>{error}</p>}
                  <div className={style.cardButton}>
                    <button onClick={handleVerifyOtp}>Send</button>
                  </div>
                  <div className={style.cardBottomText}>
                    <p>
                      Didn’t receive code? <span onClick={handleSendEmail}>Resend</span>
                    </p>
                  </div>
                </div>
              </div>
            ) : contentShow === "passwordSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>Enter a new, strong password!</span>
                </div>
                <div className={style.cardBody}>
                <div className={`${style.emailInput} ${passwordError && style.errorInput}`}>
                  <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Password" 
                  name="Password"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                  />
                   <span onClick={togglePasswordVisibility} className={style.eyeIco}>
                      {showPassword ? <RxEyeOpen /> : <RxEyeClosed />}
                    </span>
                </div>
                <div className={`${style.emailInput} ${confirmPasswordError && style.errorInput}`}>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    required
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError(false);
                    }}
                  />
                </div>
                {passwordError && (
                  <div className={style.passwordCondition}>
                    <div className={style.specialCharacter}>*</div>
                    <div className={style.passwordConditionText}>
                      <p>
                        At least 8 characters, 1 uppercase letter,{" "}
                        <span>1 number</span> and{" "}
                        <span>1 special character.</span>
                      </p>
                    </div>
                  </div>
                )}
                  <div className={style.cardButton}>
                    <button onClick={handleResetPassword}>Save</button>
                  </div>
                </div>
              </div>
            ) : contentShow === "successSection" ? (
              <div className={style.successContent}>
                <div className={style.successImage}>
                  <img src={SuccessImage} alt="Success" />
                </div>
                <div className={style.successText}>
                  <p>Your password has been changed successfully.</p>
                </div>
                <div className={style.successButton}>
                  <button onClick={closePopup}>Done</button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {avatarPopup?
     <>
      <div className={style.popupOverlay} onClick={()=>{
        setAvatarPopup(false)
        setSelectedAvatar({})
      }}></div>
        <div className={style.avatarPopup}>
        <div className={style.avatarPopupBody}>
          <div className={style.avatarGrid}>
            {avatarData&&avatarData.length>0?avatarData.map((data,index)=>{
              return(
                <div onClick={()=>{
                  setSelectedAvatar(data)
                }} key={index} className={`${style.avatarImage} ${selectedAvatar.avatar_id===data.avatar_id?style.selectedImage:""}`}>
                  <img src={data.avatar_url} alt={data.avatar_id}/>
                </div>
              )
            }):"No avatar found"}
          </div>
        </div>
        <div className={style.avatarButton}>
          <button onClick={()=>{
            setProfileData({...profileData, profile_image: selectedAvatar.avatar_url })
            setAvatarPopup(false)
          }}>Save</button>
        </div>
        </div>
     </>
      :null}
      {viewPopUp&&(
        <>
        <div className={style.popupOverlay}></div>
          <div className={style.achievementPopup}>
            <div className={style.achievementPopupHeder}>
              <p style={{
            color: viewData.status
              ? viewData.sectionType === 'daily'
                ? '#FFBE00'
                : viewData.sectionType === 'weekly'
                ? '#00DFC7'
                : '#595BDB' 
              : '#5F5F5F',
          }}>{viewData.name||""}</p>
              <span>{viewData.description}</span>
            </div>
           <div className={style.achievementPopupImageContainer}>
              <div className={style.achievementPopupImage}>
                <img src={viewData.status ? viewData.active_img : viewData.default_img} alt={viewData.type}/>
              </div>
              <div className={style.achievementPoint}>
                {viewData.sectionType==="achievement"&&viewData.status?  <p>x {viewData.count}</p>:""}
                </div>
           </div>
            <div className={style.achievementPopupText}>
              {viewData.status?
              <p>It's a result of your hard work! <br/>Keep going</p>
              :
              <p>Unlock all the modules in <br/>order to achieve this</p>}
            </div>
            <div className={style.achievementPopupButton}>
             <button  style={{
            backgroundColor: viewData.status
              ? viewData.sectionType === 'daily'
                ? '#FFBE00'
                : viewData.sectionType === 'weekly'
                ? '#00DFC7'
                : '#595BDB' 
              : '#5F5F5F', 
          }}onClick={()=>{
              setViewPopup(false);
              setViewData({})
             }}>Ok</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
