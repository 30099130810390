import React from "react";
import style from "./Ready.module.css";
import ready from "../../assests/ready.png";
import { useNavigate } from "react-router-dom";


function Ready() {
  const navigate = useNavigate();
  const UserName=localStorage.getItem("firstName")

  return (
    <div className={style.Container}>
      <div className={style.ReadyCard}>
        <div className={style.cardBody}>
          <div className={style.Cardheading}>
            <h3>
              Welcome<span> {UserName}!</span>
            </h3>

            <p> Start your journey to English mastery! </p>
          </div>

          <div className={style.ReadyImg}>
            <img src={ready} alt="Success Img " />
          </div>

          <div className={style.Readymsg}>
            <p>
              Congratulations for taking the first steps for learning English. 
            </p>
            <p>
            Each word you learn brings you one step closer to fluency.  
            </p>
          </div>

          <div className={style.start}>
            <button onClick={() => navigate("/start")} >Let’s start!</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ready;
